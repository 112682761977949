import { useTablesStore } from '@/store';

export const useTableInitialization = () => {
  const tablesStore = useTablesStore();

  const addFunctionToCleanTableStore = () => {
    window.clearTableStorage = () => {
      tablesStore.tablesSettings = {};
      console.info('Table tablesStore was cleared. Page will be refreshed now');
      setTimeout(() => {
        document.location.reload();
      }, 3000);
    };
  };

  const refreshTableStoreWhenFirstUsageAfterUpdate = () => {
    const hasValuesInStore = Object.entries(tablesStore.tablesSettings).length;

    if (tablesStore.shouldHardRefresh6 && window.clearTableStorage && hasValuesInStore) {
      window.clearTableStorage();
    }
    tablesStore.shouldHardRefresh6 = false;
  };

  return { addFunctionToCleanTableStore, refreshTableStoreWhenFirstUsageAfterUpdate };
};
