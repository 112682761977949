import { computed } from 'vue';

import { useStore } from '@/store';
import { useToken } from '@/composables/useToken';
import { router } from '@/router';

export const useService = () => {
  const store = useStore();
  const { isAdminRole } = useToken();
  const isAdminZone = computed(() => (isAdminRole.value || router.currentRoute?.value?.meta?.layout === 'AdminAuth'));
  const isServicePage = computed(() => (store.lastResponse?.isServicePage && isAdminZone.value === false));
  const serviceInfo = computed(() => (store.lastResponse || {}));

  return { isServicePage, serviceInfo };
};
