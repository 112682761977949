import { clearAxiosCashedState, useFetch } from '@/api';
import { router, routerPushWithoutGuards } from '@/router';
import { useAdminStore, useStore } from '@/store';
import { clearInstances } from '@/composables/usePagination';
import { useToken } from '@/composables/useToken';
import { isFreshResponse } from '@/views/merchant/MerchantDashboard/api';
import { updateAxiosToken } from '@/composables/useFetch/helpers';

export const apiHealthCheck = async () => {
  const { axiosWrapper } = useFetch();

  const { isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/settings/admin/health-check',
    params: { isFreshResponse: true },
    isErrorNotification: false,
  });

  return { isSuccess };
};

export const clearState = ({ pushToHome }) => {
  const store = useStore();
  const { language, preferences } = store;
  updateAxiosToken(null);
  isFreshResponse.value = false;

  const adminStore = useAdminStore();
  adminStore.clearProfile();

  if (pushToHome === undefined || pushToHome === true) {
    routerPushWithoutGuards(() => {
      router.push('/');
    });
  }

  setTimeout(() => {
    store.clearProfile();
    store.$reset();
    clearInstances();
    clearAxiosCashedState();
    store.$patch({ language, preferences });
  }, 200);
};

export const logout = async ({ pushToHome, useLogoutEndpoint = true } = {
  pushToHome: false,
  useLogoutEndpoint: true,
}) => {
  const { axiosWrapper } = useFetch();

  if (!useLogoutEndpoint) {
    clearState({ pushToHome });
    return;
  }

  const { isSuccess } = await axiosWrapper({
    type: 'post',
    url: '/auth/logout',
    useRefreshToken: true,
  });

  if (isSuccess) {
    clearState({ pushToHome });
  }
};

export const twoFaCodeGenerate = async (text = 'Switch', isFresh = false) => {
  const { axiosWrapper } = useFetch();

  const { data, isSuccess, error } = await axiosWrapper({
    type: 'post',
    url: '/merchants/totp/generate',
    params: {
      isFreshResponse: isFresh,
    },
  });

  const otpLink = isSuccess ? `otpauth://totp/${text}?secret=${data.secret}` : '';

  return {
    isSuccess, data, otpLink, error,
  };
};

export const twoFaEnable = async (totpCode, { isErrorNotification } = {
  isErrorNotification: true,
}) => {
  const { axiosWrapper, successHandler } = useFetch();
  const {
    data, isSuccess, error, errorObject,
  } = await axiosWrapper({
    type: 'post',
    url: '/merchants/totp/enable',
    params: { totpCode },
    isErrorNotification,
  });
  return {
    data, isSuccess, successHandler, error, errorObject,
  };
};

export const twoFaAuthentificate = async ({ totpCode, role }) => {
  const store = useStore();
  const { axiosWrapper, successHandler } = useFetch();
  const {
    data, isSuccess, error, errorObject,
  } = await axiosWrapper({
    type: 'post',
    url: '/auth/totp/authenticate',
    params: { totpCode },
  });

  if (isSuccess && role) {
    store.$patch({ role });
  }

  return {
    data, isSuccess, successHandler, error, errorObject,
  };
};

export const resendEmail = async () => {
  const { axiosWrapper, successHandler } = useFetch();
  const { data, isSuccess, error } = await axiosWrapper({
    type: 'post',
    url: '/merchants/register/resend-email',
  });
  return {
    data, isSuccess, successHandler, error,
  };
};

export const verifyEmail = async (code, { isErrorNotification = false }) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { data, isSuccess, error } = await axiosWrapper({
    type: 'post',
    url: '/merchants/register/verify-code',
    params: { code: String(code) },
    isErrorNotification,
  });

  if (isSuccess) {
    const store = useStore();
    store.$patch({
      role: 'merchant',
      uid: data.uid,
    });
  }

  return {
    data, isSuccess, successHandler, uid: data?.uid, error,
  };
};

export const deleteAccount = async (totpCode) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, errorObject } = await axiosWrapper({
    type: 'delete',
    url: '/merchants/',
    params: { totpCode },
  });
  return { isSuccess, errorObject };
};

export const generateRecoveryCodes = async ({ isErrorNotification } = {
  isErrorNotification: true,
}) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess, error } = await axiosWrapper({
    type: 'get',
    url: '/auth/totp/recovery-keys/generate',
    isErrorNotification,
  });
  return { data, isSuccess, error };
};

export const AUTH_REFRESH = '/auth/refresh';
export const refreshTokens = async () => {
  const { axios, axiosWrapper } = useFetch();
  const { refreshToken } = useToken();

  if (refreshToken.value) {
    axios.defaults.headers.Authorization = `Bearer ${refreshToken.value}`;
  } else {
    console.log('isSuccess: false');
    return { isSuccess: false };
  }

  const { data, isSuccess } = await axiosWrapper({
    type: 'post',
    url: AUTH_REFRESH,
  });

  return {
    isSuccess,
    accessToken: data?.access?.token,
    refreshToken: data?.refresh?.token,
  };
};

export default {
  clearState,
  logout,

  twoFaCodeGenerate,
  twoFaEnable,
  twoFaAuthentificate,
  resendEmail,
  deleteAccount,
  generateRecoveryCodes,
};
