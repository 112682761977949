import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { useSystemStore } from '@/store';
import { instance } from '@/composables/useFetch';
import { temporaryInstance } from '@/composables/useFetch/temporaryInstance';

const updateDeviceId = async (deviceId) => {
  if (deviceId) {
    instance.defaults.headers.device_id = deviceId;
    temporaryInstance.defaults.headers.device_id = deviceId;
  }
};

const generateSalt = async () => {
  const systemStore = useSystemStore();
  if (systemStore.salt === null) {
    const salt = Math.random();
    systemStore.$patch({ salt });
  }
};

const getDeviceId = async () => {
  const fpPromise = await FingerprintJS.load();
  const fp = await fpPromise;
  const result = await fp.get();
  const { screenResolution, screenFrame, ...components } = result.components;

  const deviceId = FingerprintJS.hashComponents(components);

  return deviceId;
};

export const useFingerprint = async () => {
  const systemStore = useSystemStore();
  const storedDeviceId = systemStore.getDeviceId;

  if (storedDeviceId) {
    updateDeviceId(storedDeviceId);
  } else {
    generateSalt();
    const deviceId = await getDeviceId();
    systemStore.$patch({ deviceId });

    updateDeviceId(systemStore.getDeviceId);
  }
};
