<template>
  <Simplebar
    ref="scrollContainer"
    class="simplebar"
    :class="{
      'has-full-height': hasFullHeight === true,
      dark: isDark,
    }"
    @scroll="onScroll"
  >
    <slot />
  </Simplebar>
</template>

<script setup>
import Simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import { ref } from 'vue';

import { checkScrollYEnd } from '@/utils/functions';

defineProps({
  hasFullHeight: {
    type: Boolean,
    default: true,
  },
  isDark: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['scroll-end', 'scroll-top']);

const scrollContainer = ref(null);

const onScroll = (e) => {
  emit('scroll-top', e.target.scrollTop);
  checkScrollYEnd(e, () => { emit('scroll-end'); });
};
</script>

<style lang="scss" scoped>
.simplebar {
  height: 100%;
  max-height: inherit;
  overflow-x: hidden;

  &.has-full-height {
    :deep(.simplebar-wrapper),
    :deep(.simplebar-mask),
    :deep(.simplebar-offset),
    :deep(.simplebar-content),
    :deep(.simplebar-content-wrapper) {
      @include full-height;
    }
  }

  :deep(.simplebar-wrapper),
  :deep(.simplebar-mask),
  :deep(.simplebar-offset),
  :deep(.simplebar-content),
  :deep(.simplebar-content-wrapper) {
    &:focus-visible {
      outline: unset;
    }
  }

  &.dark {
    :deep(.simplebar-track.simplebar-vertical) {
      .simplebar-scrollbar:before {
        background-color: white;
      }
    }
  }

  @include mq('mobile') {
    :deep(.simplebar-track.simplebar-vertical) {
      width: 7px;
    }
  }
}
</style>
