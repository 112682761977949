<template>
  <div class="reset" @click.stop="$emit('reset')">
    <AppIcon name="x-close-small" size="18px" is-img-tag />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['reset'],
});
</script>

<style scoped lang="scss">
.reset {
  position: absolute;
  top: 0;
  left: 1px;
  height: 100%;
  aspect-ratio: 1 / 1;
  background: #E6E6E6;
  border-radius: 0 3px 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include transition-base(background);
  &:hover {
    background: #D9D9D9;
  }
}
</style>
