<template>
  <ElConfigProvider :locale="locale">
    <AppNotification />
    <AppService v-show="isServicePage" :info="serviceInfo" />
    <AppLayout v-show="!isServicePage" />
  </ElConfigProvider>
</template>

<script setup>
import { onErrorCaptured, watch } from 'vue';
import { ElConfigProvider } from 'element-plus';
import { useNetwork } from '@vueuse/core';

import AppNotification from '@/components/Notification/AppNotification.vue';

import AppService from '@/views/service/AppService.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import { initTranslations, i18n } from '@/plugins/localization';
import { app } from '@/main';
import { useSocket } from '@/composables/useSocket';
import { updateAppVersion } from '@/utils/functions';
import useNotifications from '@/composables/useNotifications';

import { useTableInitialization } from './lib/useTableInitialization';
import { useService } from './lib/useService';
import { useElementLocalization } from './lib/useElementLocalization';
import { useFavicons } from './lib/useFavicons';
import { useFingerprint } from './lib/useFingerprint';

useFingerprint();

const { addFunctionToCleanTableStore, refreshTableStoreWhenFirstUsageAfterUpdate } = useTableInitialization();

addFunctionToCleanTableStore();
refreshTableStoreWhenFirstUsageAfterUpdate();
initTranslations(app);

const { locale } = useElementLocalization();
const { isServicePage, serviceInfo } = useService();

useFavicons();

const { addSocketListener } = useSocket();

setTimeout(() => {
  addSocketListener('newFeVersion', (data) => {
    updateAppVersion(data.feVersion);
  });
}, 0);

// This logic allows to render UI in case when translator did a mistake in template
onErrorCaptured((err, instance) => {
  if (instance.$options.name === 'i18n-t') {
    return false;
  }
  return true;
});

const { isOnline } = useNetwork();

watch(isOnline, (value) => {
  if (!value) {
    const { t } = i18n.global;
    const { addNotification } = useNotifications();
    addNotification({
      text: t('errors.CONNECTION_LOST'),
      config: { color: 'warning', duration: 5000 },
    });
  }
});
</script>
