import { computed, watch } from 'vue';

import { coloredLog } from '@/utils/coloredLog';
import { useProfile } from '@/composables/useProfile';

const createLink = ({
  rel, href, sizes, type, color,
}) => {
  const link = document?.createElement('link');
  if (sizes) {
    link.sizes = sizes;
  }
  if (type) {
    link.type = type;
  }
  if (color) {
    link.color = color;
  }
  link.rel = rel;
  link.href = href;
  document?.head.append(link);
};

/* eslint-disable */
export const useFavicons = () => {
  const { isAdminZone } = useProfile();
  const isDarkMode = computed(() => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  const setAdminDarkPackage = () => {
    coloredLog('ADMIN DARK ICONS IS USED');

    createLink({ rel: 'apple-touch-icon', href: '/favicon-dark/apple-touch-icon.png', sizes: '180x180' });
    createLink({ rel: 'icon', href: '/favicon-dark/favicon-32x32.png', sizes: '32x32', type: 'image/png' });
    createLink({ rel: 'icon', href: '/favicon-dark/favicon-16x16.png', sizes: '16x16', type: 'image/png' });
    createLink({ rel: 'mask-icon', href: '/favicon-dark/safari-pinned-tab.svg', color: '#d48343' });
    createLink({ rel: 'icon', href: '/favicon-dark/favicon.svg', type: 'image/svg+xml' });
    createLink({ rel: 'icon', href: '/favicon-dark/favicon.ico' });
  };

  const setPackage = () => {
    if (isAdminZone.value && isDarkMode.value) {
      setAdminDarkPackage();
    }
  };

  watch(isAdminZone, () => {
    setPackage();
  }, { immediate: true });

};

export default { useFavicons };
