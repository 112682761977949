<template>
  <div
    ref="tooltip"
    class="tooltip"
    :class="{ 'is-disabled': !isEnabled }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @mousemove="onMouseMove"
  >
    <slot :is-enabled="isEnabled" />

    <teleport to="body">
      <transition name="show">
        <div
          v-if="isEnabled && isVisible"
          ref="tip"
          :style="styles"
        >
          <slot name="text" />
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

export default {
  name: 'AppTooltip',
  props: {
    width: {
      type: String,
      default: 'auto',
    },
    textAlign: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'center', 'right'].includes(value),
    },
    isEnabled: {
      type: Boolean,
      default: true,
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    cursor: {
      type: String,
      default: 'pointer',
    },
  },
  emits: ['onVisibleChange'],
  setup(props, { emit }) {
    const tooltip = ref(null);
    const tip = ref(null);
    const isVisible = ref(false);

    const styles = computed(() => ({
      position: 'fixed',
      zIndex: 9999,
      background: 'var(--color-black-07)',
      color: 'var(--color-white)',
      padding: '8px 12px',
      borderRadius: '6px',
      fontSize: '13px',
      lineHeight: 1.5,
      top: position.value.top,
      left: position.value.left,
      width: props.width,
      textAlign: props.textAlign,
      opacity: isVisible.value ? 1 : 0,
    }));

    const position = ref({
      top: 0,
      left: 0,
    });

    const onMouseEnter = (e) => {
      setTooltipPosition(e);
      isVisible.value = true;
      emit('onVisibleChange', true);
    };

    const onMouseLeave = () => {
      isVisible.value = false;
      emit('onVisibleChange', false);
    };

    const onMouseMove = (e) => {
      setTooltipPosition(e);
    };

    const setTooltipPosition = (event) => {
      position.value.left = `${getDeltaX(event)}px`;
      position.value.top = `${event.clientY + 16}px`;
    };

    const getDeltaX = (event) => {
      let deltaX = -1000;

      if (tip.value) {
        deltaX = 0;
        const tipWidth = tip.value.getBoundingClientRect().width;

        const windowWidth = event.view.innerWidth || event.view.clientX;
        deltaX = event.clientX - tipWidth * 0.5;

        if (deltaX + tipWidth > windowWidth) {
          deltaX = windowWidth - tipWidth;
        }
      }

      return deltaX;
    };

    const displayType = computed(() => (props.isBlock ? 'flex' : 'inline-flex'));

    return {
      isVisible,
      onMouseEnter,
      onMouseMove,
      onMouseLeave,
      tooltip,
      tip,
      position,
      styles,
      displayType,
    };
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  cursor: v-bind('cursor');
  display: v-bind('displayType');
  align-items: center;
}
</style>
