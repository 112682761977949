import { useFetch } from '@/api';
import { useStore } from '@/store';
import { resetDateFilters } from '@/composables/usePagination';
import { useLang } from '@/composables/useLang';
import { useSettings } from '@/composables/useSettings';
import { useUser } from '@/composables/useUser';
import { useBillingHistory } from '@/composables/useBillingHistory';

export const setAppLanguage = () => {
  const { settings } = useSettings();
  const { setLanguage } = useLang();

  setLanguage(settings.value.language);
};

export const getSettings = async (params = {}) => {
  let isFreshResponse = true;
  // eslint-disable-next-line no-prototype-builtins
  if (params.hasOwnProperty('isFreshResponse')) {
    isFreshResponse = params.isFreshResponse;
  }

  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/settings/merchant',
    params: {
      ...params,
      isFreshResponse,
    },
  });

  if (isSuccess) {
    const store = useStore();
    store.updateMerchantSettings(data);
    setAppLanguage();
  }

  return { data, isSuccess };
};

export const getMerchantProfile = async (params = {}) => {
  const store = useStore();
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/merchants/profile',
    params,
  });
  if (isSuccess) {
    store.initProfile(data);
    store.updateWalletConnectedState(data.settings);

    const { user } = useUser();
    const hasAvatar = user.value.profileAvatar && user.value.profileOriginalAvatar;
    if (!hasAvatar) {
      store.updateProfileAvatar({
        profileAvatar: data.profilePicture?.cropUrl,
        profileOriginalAvatar: data.profilePicture?.originalUrl,
      });
    }

    if (!data.profilePicture) {
      store.updateProfileAvatar({
        profileAvatar: null,
        profileOriginalAvatar: null,
      });
    }
  }
};

export const changeMerchantPlanStatus = async ({ isActive }) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/merchants/subscriptions/plans/change-status',
    params: { isActive },
  });

  if (isSuccess) {
    const store = useStore();

    store.$patch({
      user: {
        subscription: data,
      },
    });
  }

  return { isSuccess, successHandler };
};

export const updateMerchantSettings = async (settings) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/settings/merchant',
    params: settings,
  });

  if (isSuccess) {
    successHandler(t('merchantSettings.notification.updateMerchantSettingsSuccess'));
    resetDateFilters();
    await getSettings();
  }

  return { data, isSuccess, successHandler };
};

export const updateMerchantRules = async (rules) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/settings/merchant/rules',
    params: rules,
  });

  if (isSuccess) {
    successHandler(t('merchantSettings.notification.updateRulesSuccess'));

    const store = useStore();
    store.$patch({
      settings: {
        antiSpam: data.antiSpam,
        captcha: data.captcha,
        selfDestruct: data.selfDestruct,
        rulesUpdatedAt: data.rulesUpdatedAt,
        isDetonationCodeSet: data.isDetonationCodeSet,
      },
    });
  }
  return { data, isSuccess, successHandler };
};

export const updateMerchantPlan = async ({ type, cycle }) => {
  const { axiosWrapper, successHandler } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'post',
    url: '/merchants/subscriptions/plans/upgrade',
    params: {
      type,
      cycle,
    },
  });

  return { data, isSuccess, successHandler };
};

export const updateSlippageRules = async (val) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/settings/merchant/slippage',
    params: { slippage: val },
  });

  if (isSuccess) {
    successHandler(t('merchantSettings.notification.updateSlippageSuccess'));

    const store = useStore();
    store.$patch({
      settings: {
        slippage: data.slippage,
        rulesUpdatedAt: data.rulesUpdatedAt,
      },
    });
  }

  return { data, isSuccess };
};

export const enableDetonator = async (totpCode) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { data, isSuccess, errorObject } = await axiosWrapper({
    type: 'patch',
    url: '/settings/merchant/detonator/enable',
    params: { totpCode },
  });

  if (isSuccess) {
    successHandler(t('merchantSettings.notification.enableDetonatorSuccess'));

    const store = useStore();
    store.$patch({
      settings: {
        isDetonationCodeSet: isSuccess,
      },
    });
  }

  return { data, isSuccess, errorObject };
};

export const disableDetonator = async () => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/settings/merchant/detonator/disable',
  });

  if (isSuccess) {
    successHandler(t('merchantSettings.notification.disableDetonatorSuccess'));

    const store = useStore();
    store.$patch({
      settings: {
        isDetonationCodeSet: false,
      },
    });
  }

  return { data, isSuccess };
};

export const getBillingInfo = async () => {
  const { store: billingHistoryStore } = useBillingHistory();
  const { setHistoryStartedYear } = billingHistoryStore;

  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/settings/merchant/billing',
    params: { isFreshResponse: true },
  });

  if (isSuccess) {
    setHistoryStartedYear(data.depositStartYear);
  }

  return { data, isSuccess };
};

const desiredStatuses = ['WAITING', 'UNDERPAID', 'UNCONFIRMED'];
export const checkPaymentsInProgress = (status) => desiredStatuses.includes(status);
