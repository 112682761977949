/* eslint-disable import/extensions */
import { computed } from 'vue';
import en from 'element-plus/dist/locale/en.js';
import ru from 'element-plus/dist/locale/ru.js';
import zh from 'element-plus/dist/locale/zh-cn.js';
import ja from 'element-plus/dist/locale/ja.js';
import ko from 'element-plus/dist/locale/ko.js';

import { useLang } from '@/composables/useLang';

export const useElementLocalization = () => {
  const locales = {
    ru, en, zh, ja, ko,
  };
  const { language } = useLang();
  const locale = computed(() => locales[language.value]);

  return { locale };
};
