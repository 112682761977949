<template>
  <component
    :is="variant"
    v-bind="$attrs"
    :class="{
      container__boxShadow: boxShadow,
      container__muted: muted,
      container__border: bordered,
      container__active: active,
    }"
    class="container"
    :style="{
      '--border-radius': radius,
      '--bg': backgroundColor,
      '--width': width,
    }"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'ContainerWrapper',
  inheritAttrs: true,
  props: {
    variant: {
      type: String,
      default: 'div',
    },
    boxShadow: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: String,
      default: '6px',
    },
    width: {
      type: String,
      default: 'auto',
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  background-color: var(--bg);
  box-sizing: border-box;
  border-radius: var(--border-radius);
  width: var(--width);

  &__boxShadow {
    box-shadow: $boxShadowLow;
  }
  &__border {
    border: 1px solid $borderColor;
  }
  &__muted {
    background-color: $defaultBackground;
  }
  &__active {
    background-color: $selectedBackground;
    border: 2px solid $selectedBorder;
    box-sizing: border-box;
    border-radius: 8px;
  }
}
</style>
