import { computed } from 'vue';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import ae from 'flag-icons/flags/4x3/ae.svg';
import ch from 'flag-icons/flags/4x3/ch.svg';
import cn from 'flag-icons/flags/4x3/cn.svg';
import eu from 'flag-icons/flags/4x3/eu.svg';
import gb from 'flag-icons/flags/4x3/gb.svg';
import hk from 'flag-icons/flags/4x3/hk.svg';
import il from 'flag-icons/flags/4x3/il.svg';
import ind from 'flag-icons/flags/4x3/in.svg';
import jp from 'flag-icons/flags/4x3/jp.svg';
import kr from 'flag-icons/flags/4x3/kr.svg';
import ru from 'flag-icons/flags/4x3/ru.svg';
import sg from 'flag-icons/flags/4x3/sg.svg';
import th from 'flag-icons/flags/4x3/th.svg';
import ua from 'flag-icons/flags/4x3/ua.svg';
import us from 'flag-icons/flags/4x3/us.svg';

import { i18n } from '@/plugins/localization';
import { addThousandsSeparators } from '@/utils/numberFormatter';

export const languages = [
  {
    text: 'English',
    short: 'EN',
    value: 'en',
  },
  {
    text: 'Русский',
    short: 'RU',
    value: 'ru',
  },
  {
    text: '華語',
    short: 'ZH',
    value: 'zh',
  },
  {
    text: '日本語',
    short: 'JA',
    value: 'ja',
  },
  {
    text: '한국인',
    short: 'KO',
    value: 'ko',
  },
];

export const planSelectOptions = [
  {
    text: 'Free',
    value: 'FREE',
    icon: 'plan-free',
  },
  {
    text: 'Pro',
    value: 'PRO',
    icon: 'plan-pro',
  },
  {
    text: 'Business',
    value: 'BUSINESS',
    icon: 'plan-business',
  },
  {
    text: 'Black',
    value: 'BLACK',
    icon: 'plan-black',
  },
  {
    text: 'IO',
    value: 'IO',
    icon: 'plan-io',
  },
];

export const cycleSelectOptions = [
  {
    text: 'Monthly',
    value: 'MONTHLY',
  },
  {
    text: 'Yearly',
    value: 'YEARLY',
  },
];

export const userTypeOptions = [
  { text: 'Merchant', value: 'merchant' },
  { text: 'Admin', value: 'admin' },
];

export const fullCoinsArray = [
  {
    icon: 'btc-circle',
    iconDisabled: 'btc-disabled',
    name: 'Bitcoin',
    value: 'BTC',
    isPrimary: true,
  },
  {
    icon: 'ltc-circle',
    iconDisabled: 'ltc-disabled',
    name: 'Litecoin',
    value: 'LTC',
    isPrimary: true,
  },
  {
    icon: 'eth-circle',
    iconDisabled: 'eth-disabled',
    name: 'Ethereum',
    value: 'ETH_ETH',
    shortValue: 'ETH',
    family: 'ERC-20',
    isPrimary: true,
  },
  {
    icon: 'dash-circle',
    iconDisabled: 'dash-disabled',
    name: 'Dash',
    value: 'DASH',
    isPrimary: true,
  },
  {
    icon: 'xmr-circle',
    iconDisabled: 'xmr-disabled',
    name: 'Monero',
    value: 'XMR',
    isPrimary: true,
  },
  {
    icon: 'usdt-circle',
    iconDisabled: 'usdt-disabled',
    familyIcon: 'eth-network',
    familyIconDisabled: 'eth-network-disabled',
    name: 'USDT',
    family: 'ERC-20',
    value: 'ETH_USDT',
  },
  {
    icon: 'usdt-circle',
    iconDisabled: 'usdt-disabled',
    familyIcon: 'tron-network',
    familyIconDisabled: 'tron-network-disabled',
    name: 'USDT',
    family: 'TRC-20',
    value: 'TRON_USDT',
  },
  {
    icon: 'usdc-circle',
    iconDisabled: 'usdc-disabled',
    familyIcon: 'eth-network',
    familyIconDisabled: 'eth-network-disabled',
    name: 'USDC',
    family: 'ERC-20',
    value: 'ETH_USDC',
  },
  {
    icon: 'fdusd-circle',
    iconDisabled: 'fdusd-disabled',
    familyIcon: 'eth-network',
    familyIconDisabled: 'eth-network-disabled',
    name: 'FDUSD',
    family: 'ERC-20',
    value: 'ETH_FDUSD',
  },
  {
    icon: 'fdusd-circle',
    iconDisabled: 'fdusd-disabled',
    familyIcon: 'bsc-network',
    familyIconDisabled: 'bsc-network-disabled',
    name: 'FDUSD',
    family: 'BEP-20',
    value: 'BSC_FDUSD',
  },
  {
    icon: 'dai-circle',
    iconDisabled: 'dai-disabled',
    familyIcon: 'eth-network',
    familyIconDisabled: 'eth-network-disabled',
    name: 'DAI',
    family: 'ERC-20',
    value: 'ETH_DAI',
  },
];

export const supportedNetworksFamily = ['ETHEREUM', 'TRON', 'BSC'];

export const supportedNetworksFamilyObj = [
  { value: 'ETHEREUM', text: 'Ethereum', icon: 'eth-circle' },
  { value: 'TRON', text: 'TRON', icon: 'tron-circle' },
  { value: 'BSC', text: 'BSC', icon: 'bsc-circle' },
];

export const coinsSortWeight = {
  BTC: 1,
  LTC: 2,
  ETH_ETH: 3,
  DASH: 4,
  XMR: 5,
  ETH_USDT: 6,
  TRON_USDT: 7,
  ETH_USDC: 8,
  ETH_FDUSD: 9,
  BSC_FDUSD: 10,
  ETH_DAI: 11,
};

export const plansSortWeight = {
  FREE: 1,
  PRO: 2,
  BUSINESS: 3,
  BLACK: 4,
  IO: 5,
};

export const currencyList = [
  {
    symbol: '$',
    short: 'USD',
    minAmount: 5,
    countryCode: 'us',
    icon: us,
  },
  {
    symbol: '€',
    short: 'EUR',
    minAmount: 5,
    countryCode: 'eu',
    icon: eu,
  },
  {
    symbol: '£',
    short: 'GBP',
    minAmount: 5,
    countryCode: 'gb',
    icon: gb,
  },
  {
    symbol: '₣',
    short: 'CHF',
    minAmount: 5,
    countryCode: 'ch',
    icon: ch,
  },
  {
    symbol: '₽',
    short: 'RUB',
    minAmount: 500,
    countryCode: 'ru',
    icon: ru,
  },
  {
    symbol: '₩',
    short: 'KRW',
    minAmount: 5000,
    countryCode: 'kr',
    icon: kr,
  },
  {
    symbol: '¥',
    short: 'CNY',
    minAmount: 100,
    countryCode: 'cn',
    icon: cn,
  },
  {
    symbol: '¥',
    short: 'JPY',
    minAmount: 1000,
    countryCode: 'jp',
    icon: jp,
  },
  {
    symbol: '฿',
    short: 'THB',
    minAmount: 200,
    countryCode: 'th',
    icon: th,
  },
  {
    symbol: '₴',
    short: 'UAH',
    minAmount: 500,
    countryCode: 'ua',
    icon: ua,
  },
  {
    symbol: '₪',
    short: 'ILS',
    minAmount: 20,
    countryCode: 'il',
    icon: il,
  },
  {
    symbol: 'HK$',
    short: 'HKD',
    minAmount: 100,
    countryCode: 'hk',
    icon: hk,
  },
  {
    symbol: 'S$',
    short: 'SGD',
    minAmount: 10,
    countryCode: 'sg',
    icon: sg,
  },
  {
    symbol: '₹',
    short: 'INR',
    minAmount: 500,
    countryCode: 'in',
    icon: ind,
  },
  {
    symbol: 'د.إ',
    short: 'AED',
    minAmount: 20,
    hasArabicStyle: true,
    countryCode: 'ae',
    icon: ae,
  },
];

export const dateFormats = computed(() => {
  const { t } = i18n.global;
  return [
    { label: t('format.date.dateMonthYear'), value: 'DD/MM/YYYY' },
    { label: t('format.date.yearMonthDate'), value: 'YYYY/MM/DD' },
    { label: t('format.date.monthDateYear'), value: 'MM/DD/YYYY' },
  ];
});

export const timeFormats = computed(() => {
  const { t } = i18n.global;
  return [
    { label: t('format.time.12h'), value: 'h:mm A' },
    { label: t('format.time.24h'), value: 'HH:mm' },
  ];
});

export const numberFormats = computed(() => {
  const { t } = i18n.global;
  const commaValue = `${addThousandsSeparators({ val: 1000000, separator: 'comma' })}.00`;
  const pointValue = `${addThousandsSeparators({ val: 1000000, separator: 'point' })},00`;
  const noDelimiterValue = '1000000';

  return [
    { label: t('format.number.comma', { value: commaValue }), value: 'comma' },
    { label: t('format.number.point', { value: pointValue }), value: 'point' },
    { label: t('format.number.noDelimeter', { value: noDelimiterValue }), value: 'normal' },
  ];
});

const proPlan = {
  name: 'pro',
  title: 'PRO',
  color: '#D48343',
  icon: 'plan-pro',
  perMonth: null,
  perYear: 1000,
  limits: {
    payments: 50,
    subscriptions: 100,
    clients: 100,
  },
};

export const accountTypes = {
  free: {
    ...proPlan,
    name: 'free',
    title: 'STANDARD (free)',
  },
  pro: proPlan,
  business: {
    name: 'business',
    color: '#922631',
    icon: 'plan-business',
    title: 'BUSINESS',
    perMonth: 1000,
    perYear: 10000,
    limits: {
      payments: 500,
      subscriptions: 300,
      clients: 300,
    },
  },
  black: {
    name: 'black',
    color: '#000000',
    icon: 'plan-black',
    title: 'BLACK',
    perMonth: 3000,
    perYear: 30000,
    limits: {
      payments: 'Unlimited',
      subscriptions: 'Unlimited',
      clients: 'Unlimited',
    },
  },
};

export const APP_ROLES = Object.freeze({
  USER: 'user',
  CLIENT: 'client',
  MERCHANT: 'merchant',
  ADMIN: 'admin',
  SUPER_ADMIN: 'superadmin',
});

export const numberTypes = Object.freeze({
  INT: 'INT', // => 1
  INT00: 'INT00', // => 1.00
  FLOAT2: 'FLOAT2', // => 1.12
  FLOAT4: 'FLOAT4', // => 1.1234
  FLOAT5: 'FLOAT5', // => 1.12345
  TRUNC2: 'TRUNC2', // 0.999 => 0.99
  TRUNC4: 'TRUNC4', // 0.9999 => 0.9999
});

export const months = Object.freeze([
  { name: 'january', text: 'Jan', value: 0 },
  { name: 'february', text: 'Feb', value: 1 },
  { name: 'march', text: 'Mar', value: 2 },
  { name: 'april', text: 'Apr', value: 3 },
  { name: 'may', text: 'May', value: 4 },
  { name: 'june', text: 'Jun', value: 5 },
  { name: 'july', text: 'Jul', value: 6 },
  { name: 'august', text: 'Aug', value: 7 },
  { name: 'september', text: 'Sep', value: 8 },
  { name: 'october', text: 'Oct', value: 9 },
  { name: 'november', text: 'Nov', value: 10 },
  { name: 'december', text: 'Dec', value: 11 },
]);

export const years = Object.freeze([
  2023,
  2024,
  2025,
  2026,
  2027,
  2028,
]);

export const maskitoRules = {
  doubleWithTwoDecimal: maskitoNumberOptionsGenerator({
    precision: 2,
    min: 0,
    thousandSeparator: '',
  }),
  onlyInteger: maskitoNumberOptionsGenerator({
    precision: 0,
    min: 0,
    thousandSeparator: '',
  }),
  totp: {
    mask: /^[0-9/]+$/i,
  },
  email: {
    mask: /^[\w-+@.]+$/i,
  },
  date: {
    mask: /^[0-9-/]+$/i,
  },
  datetime: {
    mask: /^[0-9a-zA-Z-/():]+$/i,
  },
  onlyChars: {
    mask: /^[a-zA-Z]+$/i,
  },
  numsAndChars: {
    mask: /^[0-9a-zA-Z]+$/i,
  },
  ipAddr: {
    mask: /^[0-9a-z.:]+$/i,
  },
  all: {
    mask: /.*/gm,
  },
};

export const stickiesTypes = Object.freeze({
  INCIDENT: {
    text: 'Incident',
    value: 'INCIDENT',
    icon: 'pin-flag',
  },
  NEWS: {
    text: 'News',
    value: 'NEWS',
    icon: 'tabledocument',
  },
});

export const SERVICE_PAGES = Object.freeze({
  ACCESS_RESTRICTED: {
    value: 'ACCESS_RESTRICTED',
    code: 403,
    uri: '/access-restricted',
  },
  TOO_MANY_REQUESTS: {
    value: 'TOO_MANY_REQUESTS',
    code: 429,
  },
  UNDER_MAINTENANCE: {
    value: 'UNDER_MAINTENANCE',
    code: 503,
    uri: '/under-maintenance',
  },
});

export const NUMBER_SEPARATORS = Object.freeze({
  comma: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  point: {
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
  normal: {
    thousandSeparator: '',
    decimalSeparator: '.',
  },
});
